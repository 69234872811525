import "./Accueil.css";
import { Helmet } from "react-helmet";

export default function Accueil() {
  return (
    <div className="accueil">
      <Helmet>
        <title>Lvpa Web : Accueil</title>
      </Helmet>

      <div className="container-acc">
        <div className="box-moustache">
          <div className="box2">
            <h1 className="moustache-le-titre">LVPA WEB: </h1>
            <h2 className="blog-sous-titre">Blog</h2>
          </div>
        </div>
      </div>
    </div>
  );
}
