import { NavLink, Link } from "react-router-dom";
import logo from "./../../img/lvpasansfonsoff.png";

import "./Navbar.css";

export default function Navbar(props) {
  const isLoggedIn = props.isLoggedIn;

  return (
    <div className="navbar">
      <nav className="nav-principal">
        <ul className="liste">
          <img className="logo-site-lvpa-web" src={logo} alt="" />

          <li className="liste-item">
            <NavLink className="texte-nav" to="/">
              Accueil
            </NavLink>
          </li>
          <li className="liste-item">
            <NavLink className="texte-nav" to="/blog">
              Blog
            </NavLink>
          </li>
          {/* <li className="liste-item">
            <NavLink className="texte-nav" to="article">
              Écrire un article
            </NavLink>
          </li> */}
          {/* 
          <li className="liste-item">
            <NavLink className="texte-nav" to="/login">
              Connexion
            </NavLink>
          </li> */}
        </ul>
      </nav>
    </div>
  );
}
