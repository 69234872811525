// import Css
import "./FormBlog.css";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useState, useEffect } from "react";
import service from "../../service";
import { useNavigate } from "react-router-dom";

// Import dependence

export default function Form(props) {
  // UITLISER AVEC BACK ENTRAINEMENT REACT
  const [titre, setTitre] = useState("");
  const [contenu, setContenu] = useState("");
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [image, setImage] = useState("");

  
  useEffect(()=>{
    console.log(image);
  },[image])


  console.log(props.isLoggedIn);

  const navigate = useNavigate();

  // fonction de submit
  
  function handleChange(e) {
    console.log(e.target.files);
    setImage(URL.createObjectURL(e.target.files[0]));
}


  async function handleSubmitPost(e) {
    e.preventDefault();

    if (!contenu) {
      alert("Pas de contenu");
    }
    if (!titre) {
      alert("Pas de titre");
    } else {
      console.log("Formadata en cours");

      // e.target pour tout les inputs (normaux)
      let formData = new FormData(e.target);
      // ajout en plus du ckeditor dans le formData enfin
      formData.append("contenu", contenu);
      

      let body = formData;

      let postSubmit = await service.post(body);
      if (postSubmit.data.success) {
        console.log(postSubmit.data);
        setMessage(postSubmit.data.message);
        setTimeout(() => {
          navigate("/blog");
        }, 2000);
      } else {
        setError(postSubmit.data.error);
        setMessage(postSubmit.data.message)
        setTimeout(()=>{
          setError("");
          setMessage("")
        },2000)
      }
    }
  }

  // Configuration pour Ckeditor
  ClassicEditor.defaultConfig = {
    toolbar: {
      items: [
        "heading",
        "|",
        "bold",
        "italic",
        "|",
        "link",
        "|",
        "bulletedList",
        "numberedList",
        "|",
        "blockQuote",
        "|",
        // "insertTable",
        // "tableColumn",
        // "tableRow",
        // "mergeTableCells",
        // "|",
        "undo",
        "redo",
      ],
    },
    heading: {
      options: [
        {
          model: "paragraph",
          title: "Paragraph",
          class: "ck-heading_paragraph",
        },
        // ici je retire le h1 pour ne garder que les titre de niveau inférieur
        // { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
        {
          model: "heading2",
          view: "h2",
          title: "Heading 2",
          class: "ck-heading_heading2",
        },
        {
          model: "heading3",
          view: "h3",
          title: "Heading 3",
          class: "ck-heading_heading3",
        },
        {
          model: "heading4",
          view: "h4",
          title: "Heading 4",
          class: "ck-heading_heading4",
        },
        {
          model: "heading5",
          view: "h5",
          title: "Heading 5",
          class: "ck-heading_heading5",
        },
        {
          model: "heading6",
          view: "h6",
          title: "Heading 6",
          class: "ck-heading_heading6",
        },

        //  ici on peut rajouter des balises
      ],
    },
    image: {
      toolbar: [
        "imageStyle:inline",
        "imageStyle:block",
        "imageStyle:side",
        "|",
        "toggleImageCaption",
        "imageTextAlternative",
      ],
    },
    // This value must be kept in sync with the language defined in webpack.config.js.
    language: "en",
  };

  const onChange = (e, setter) => {
    setter(e.target.value);
  };

  return (
    <div className="editeur-cont">
      <h1 className="title-form">Écrivez un article</h1>

      <form className="container-form" onSubmit={handleSubmitPost}>
        <div className="cont-inp-titre">
          <input
            onChange={(e) => onChange(e, setTitre)}
            name="titre"
            className="inp-title"
            placeholder="Votre titre ici...."
          ></input>
        </div>
        <div className="editeur">
          <CKEditor
            className="editeur"
            editor={ClassicEditor}
            data={contenu}
            onChange={(event, editor) => {
              const data = editor.getData();
              setContenu(data);
            }}
          />
        </div>

        <div className="photo">
          <input
            // onChange={(e) => {
            //   setImage(e.target.files[0].filename);
            // }}
            onChange={handleChange}
            type="file"
            name="pictureUrl"
            // data={image}
          />
        </div>
        <div className="test-img">
          {image && <img className="test" src={image} alt="" />}        
        </div>
        <div className="bouton-center">
          <h2 className="error">{error}</h2>
          <h2 className="message">{message}</h2>
          <button className="bouton">Envoyer</button>
        </div>
      </form>
    </div>
  );
}
