import { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import "./App.css";
import Accueil from "./Components/Accueil/Accueil";
import Articles from "./Components/Articles/Articles";
import Blog from "./Components/Blog/Blog";
import Form from "./Components/FormBlog/Form";
import Navbar from "./Components/Navbar/Navbar";
import Login from "./Pages/Login";
import service from "./service";

function App() {

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState("");

  useEffect(() => {
    console.log("Chargement de la page D'accueil");
  }, []);

  
  // useEffect(() => {
  //   let info = service.checkToken()
  //   .then((response)=> {
  //     setIsLoggedIn(response.data.success)
  //   })
  //   setUser(info);
  // }, []);

  // useEffect(() => {
  //   if (user?.data?.success) {
  //     setIsLoggedIn(true);
  //     console.log(isLoggedIn);
  //   } else {
  //     setIsLoggedIn(false);
  //   }
  // }, [user]);


  return (
    <div className="App">
      <Navbar />
      <Routes>
        <Route path="/" element={<Accueil />}></Route>
        <Route path="/login" element={<Login />}></Route>
        <Route path="/blog" element={<Blog />}></Route>
        <Route path="/article" element={<Form user={user} isLoggedIn={isLoggedIn} />}></Route>
        <Route path="/blog/article/:slug" element={<Articles />}></Route>
      </Routes>
    </div>
  );
}

export default App;
