import parse from "html-react-parser";
import { useState } from "react";
import accueilpa from "./mike-van-den-bos-jf1EomjlQi0-unsplash (1).jpg";
import "./Blog.css";

import { slice } from "lodash";
import Carte from "../Carte/Carte";
import { Link } from "react-router-dom";
import bad from "./smiley.svg";
import DataBlog from "../../Data/DataBlog";
import { Helmet } from "react-helmet";

export default function Blog() {
  const [articles, setArticles] = useState([]);
  const [index, setIndex] = useState(8);
  const [isCompleted, setIsCompleted] = useState(false);

  const Data = DataBlog();

  console.log(Data, "dataBlog");

  const art = slice([...articles].reverse(), 0, index);
  const art1 = slice([...Data].reverse(), 0, index);
  console.log(art1, "art1");

  const loadMore = () => {
    setIndex(index + 4);
    console.log(index);
    if (index >= Data.length) {
      setIsCompleted(true);
    } else {
      setIsCompleted(false);
    }
  };

  return (
    <div className="main-carte">
      <Helmet>
        <title>Lvpa Web : Le Blog</title>
      </Helmet>
      <h1 className="titre-articles">Tous les articles ...</h1>

      {art1 === "" ? (
        <h2 className="article-nul">Articles en cours de chargement ...</h2>
      ) : (
        <div className="container-carte">
          {art1.map((item, index) => {
            return (
              <Carte key={index}>
                <div className="carte-blog">
                  {item.pictureUrl ? (
                    <img
                      className="img-carte"
                      src={`${item.pictureUrl}`}
                      alt=""
                    />
                  ) : (
                    <img className="img-carte" src={`${accueilpa}`} alt="" />
                  )}

                  <h2 className="titre-article-blog">{item.titre}</h2>

                  <div className="contenu">
                    <div className="carte-paragraphe">
                      {parse(item.contenu)}
                    </div>
                  </div>
                  <div className="btn-div">
                    <div className="btn-carte">
                      {" "}
                      <Link
                        className="linkCardToArticle"
                        to={`article/${item.titre.replace(/\s+/g, "-").trim()}`}
                        state={{
                          titre: item.titre,
                          contenu: item.contenu,
                          pictureUrl: item.pictureUrl,
                          auteur: item.auteur,
                          date: item.date,
                        }}
                      >
                        Lire L'article : {item.titre}
                      </Link>{" "}
                    </div>
                  </div>
                </div>
              </Carte>
            );
          })}
        </div>
      )}

      {art1 === "" ? (
        ""
      ) : (
        <div className="more">
          {!isCompleted ? (
            <button className="more-button" onClick={loadMore}>
              Voir plus ...
            </button>
          ) : (
            <div className="more-nop">
              Non n'avons pas d'autres articles à vous proposer pour le moment,
              mais nous travaillons dessus{" "}
              <img className="bad" src={bad} alt="emoji pas content" />
            </div>
          )}
        </div>
      )}
    </div>
  );
}
