import { useLocation } from "react-router-dom";
import parse from "html-react-parser";
import "./Articles.css";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import accueilpa from "../../img/blogAccueil.jpg";

export default function Articles() {
  const location = useLocation();
  console.log(location);

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <div className="center">
      <Helmet>
        <title>{location.state.titre}</title>
      </Helmet>
      <div className="container-image">
        {location.state.pictureUrl ? (
          <img
            className="article-image"
            src={`${location.state.pictureUrl}`}
            alt=""
            loading="lazy"
          />
        ) : (
          <img className="article-image" src={accueilpa} alt="" />
        )}
      </div>

      <h2 className="article-titre">{location.state.titre}</h2>
      <div className="article-contenu">
        {parse(location.state.contenu)}
        <p className="auteur">Par: {location.state.auteur} <br></br>
          <br></br>
          Le: {location.state.date}
        </p>
      </div>

      <div className="button">
        <p className="button-para">Cet article vous a été utile ? </p>
        <div className="container-button">
          <button className="button-in-button">Oui</button>{" "}
          <button className="button-in-button">Non</button>
        </div>
      </div>
    </div>
  );
}
